<template>
    <div class="options" v-if="item">
        <div class="header">
            <div class="btns">
                <div class="back btn">
                    <sc-button @click="back">Back</sc-button>
                </div>
                <div class="next btn">
                    <sc-button @click="next">Next</sc-button>
                </div>
            </div>
            <div class="header-name">Select Operational Message Priority</div>
        </div>
        <div class="items">
            <div class="item panel" v-for="item in items" @click="select(item)"
                 :class="{active:form.priority.id === item.id}">
                <div class="image">
                    <img :src="imageURL(item)"/>
                    <awesome-icon class="check" icon="check" v-show="form.priority.id === item.id"></awesome-icon>
                </div>
                <div class="title">
                    {{ item.attributes.title }}
                </div>
                <div class="subtitle">
                    {{ item.attributes.description_title }}
                </div>
                <div class="submessage">
                    {{ item.attributes.description_message }}
                </div>
            </div>
        </div>
    </div>


</template>

<script>
export default {
  name: "template-priority",

  props: {
    form: {}
  },

  emits: ['back', 'next', 'update:form'],

  data() {
    return {
      tab: 1,
      item: null,
      items: []
    }
  },

  computed: {},

  mounted() {
    this.item = this.form;
    this.fetch();
  },

  methods: {
    select(item) {
      if (this.item.priority.id === item.id) {
        this.item.priority = {};
      } else {
        this.item.priority = item;
      }
      this.$emit('update:form', this.item)
    },

    fetch() {
      this.$talker.api('templates/priorities')
        .then(res => {
          this.items = res.data.data;
        })
    },

    next() {
      if (!this.item.priority.id) {
        this.$notify.error('Please select a message priority to continue')
        return;
      }
      this.$router.push({
        query: {
          tab: this.tab + 1
        }
      })
    },

    back() {
      this.$router.push({
        query: {
          tab: this.tab - 1
        }
      })
    },

    imageURL(item) {
      return require('@/img/' + (item.attributes.media_src.substring(4)))
    }
  }
}
</script>

<style scoped lang="scss">
.header {
    margin: 2em 32px 3em;

    .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 545px;
        margin: auto;
    }

    .header-name {
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
        margin-top: 1em;
    }
}

.items {
    display: flex;
    justify-content: center;
    padding: 0 1.5em;

    .item {
        text-align: center;
        flex: 1;
        padding: 1em 1.5em;
        margin: 0 0.5em;

        &:hover, &.active {
            box-shadow: 0 0 0 2px $color-primary;
            cursor: pointer;
        }

        .image {
            width: 8em;
            height: 8em;
            margin: 0.5em auto;
            padding: 0.2em;
            background: #fff;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .check {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 4em;
                color: #adff2f;
            }
        }

        .subtitle {
            font-weight: 600;
            text-align: left;
            max-width: 100%;
            word-break: break-word;
            margin: 1.5em 0;
        }

        .submessage {
            text-align: left;
            max-width: 100%;
            word-break: break-word;
        }
    }
}
</style>