<template>
    <div class="options">
        <div class="header">
            <div class="btns">
                <div class="back btn">
                    <sc-button @click="back">Back</sc-button>
                </div>
                <div class="next btn">
                    <sc-button @click="next">Next</sc-button>
                </div>
            </div>
            <div class="header-name">CCIL Reference Number (Optional)</div>
        </div>
        <div class="items">
            <div class="item-desc">Enter in Control Centre Incident Log reference below</div>
            <input v-model="item.ccil_reference"/>
        </div>
    </div>


</template>

<script>
export default {
  name: "template-ccil",

  props: {
    form: {}
  },

  emits: ['back', 'next', 'update:form'],

  data() {
    return {
      tab: 3,
      item: {},
      items: []
    }
  },

  computed: {},

  mounted() {
    this.item = this.form;
  },

  methods: {
    next() {
      this.$emit('update:form', this.item)
      this.$router.push({
        query: {
          tab: this.tab + 1
        }
      })
    },

    back() {
      this.$emit('update:form', this.item)
      this.$router.push({
        query: {
          tab: this.tab - 1
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.header {
    margin: 2em 32px 2em;

    .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 545px;
        margin: auto;
    }

    .header-name {
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
        margin-top: 1em;
    }
}

.items {
    margin: 0 auto;
    padding: 0 2em;
    max-width: 800px;

    .item-desc {
        text-align: center;
        margin-bottom:0.5em;
    }
}
</style>