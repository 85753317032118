<template>
    <div class="options">
        <div class="header">
            <div class="header-name">Assign Player Groups</div>
        </div>
        <div class="cols">
            <div class="col" style="flex:2;">
                <div class="search-wrap pdw" style="padding-right:24px">
                    <div class="search-type">
                        <select v-model="searchType" @change="search(query)">
                            <option value="both">Both</option>
                            <option value="groups">Groups</option>
                            <option value="players">Players</option>
                        </select>
                    </div>
                    <sc-search @searched="search"></sc-search>

                </div>
                <sc-table :table="table" style="padding-right:24px;padding-top:14px;">

                    <template #rows>
                        <template-playergroup v-for="group in groups" class="group"
                                              :key="group.id"
                                              :group="group"
                                              :canAdd="true"
                                              :selected="selected"
                                              @add="select"
                        ></template-playergroup>
                    </template>

                </sc-table>
            </div>
            <div class="col">
                <div class="search-wrap pdw">
                </div>
                <sc-table :table="tableSelected" style="padding-left:8px;padding-top:14px;">

                    <template #rows>
                        <!--                        <div v-for="group in selected">-->
                        <!--                            {{ getGroup(group) }}-->
                        <!--                        </div>-->
                        <template-playergroup v-for="group in selected" class="group"
                                              :key="group"
                                              :id="group"
                                              :groups="allgroups"
                                              :canRemove="true"
                                              @remove="remove"
                        ></template-playergroup>
                    </template>


                </sc-table>
            </div>
        </div>
    </div>


</template>

<script>
import _ from 'lodash'
import ScSearch from "@/components/sc-search";
import TemplatePlayergroup from "@/views/app/templates/components/template-playergroup";

export default {
  name: "template-playergroups",
  components: {TemplatePlayergroup, ScSearch},
  props: {
    form: {},
    allgroups: {}
  },

  emits: ['update:form'],

  data() {
    return {
      item: {},
      items: [],
      selected: [],
      selectedGroups: [],
      groups: [],
      query: '',
      searchType: 'groups',
      searchCount: 0,
      table: this.$stable.createTable({
        name: 'All Player Groups',
        url: 'player-groups',
        fetchArguments: () => {
          return '&players=true'
        },
        columns: {
          name: {
            target: 'name',
            fill: true,
            width: 100,
          }
        }
      }),
      tableSelected: this.$stable.createTable({
        name: 'Groups selected',
        columns: {
          name: {
            target: 'attributes.name',
            fill: true,
          }
        }
      })
    }
  },

  computed: {},

  mounted() {
    this.item = this.form;

    this.selected = this.form.player_groups;
    this.groups = this.getGroupsTree(_.cloneDeep(this.allgroups))
  },

  methods: {

    getGroupsTree(_groups) {
      let ids = _groups.flatMap(x => x.id)
      let parents = _groups.filter(x => !ids.includes(x.attributes.parent_id))
      return parents.map(x => this.getGroupTree(_groups, x))
    },

    getGroupTree(_groups, _parent = {id: null}) {
      _parent['open'] = false;
      _parent['filtered'] = false;
      _parent['children'] = _groups.filter(x => x.attributes.parent_id === _parent.id)
      _parent['children'].map(x => this.getGroupTree(_groups, x))
      return _parent;
    },


    select(group) {
      let index = this.selected.indexOf(group.id)
      if (index === -1) {
        this.selected.push(group.id);
      }
      this.item.player_groups = this.selected;
      // this.$emit('update:form', this.item)
    },

    remove(group) {
      let index = this.selected.indexOf(group.id)
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
      this.item.player_groups = this.selected;
      // this.$emit('update:form', this.item)
    },

    update() {

    },

    search(search) {
      this.query = search;

      if (this.query.length === 0) {
        this.clearFilterAll(this.groups)
      } else {
        this.runFilter(this.query, this.groups);
      }
    },

    runFilter(search, groups, callback) {

      let query = search.toLowerCase();
      let queryType = this.searchType;

      for (let i = 0; i < groups.length; i++) {
        let group = groups[i];

        group.filtered = false;
        group.open = false;

        if (queryType === 'groups' || queryType === 'both') {
          let groupName = group.attributes.name.toLowerCase();
          if (groupName.indexOf(query) !== -1) {
            group.filtered = true;
            this.searchCount++;
            if (typeof callback === "function") {
              callback();
            }
          }
        }


        if (group.players) {
          for (let p = 0; p < group.players.data.length; p++) {
            let player = group.players.data[p];
            player.filtered = false;
            if (queryType === 'players' || queryType === 'both') {
              let playerName = player.attributes.name.toLowerCase();
              if (playerName.indexOf(query) !== -1) {
                this.searchCount++;
                player.filtered = true;
                group.open = true;
                if (typeof callback === "function") {
                  callback();
                }
              }
            }
          }
        }


        if (group.children && group.children.length > 0) {
          this.runFilter(search, group.children, () => {
            group.open = true;
            if (typeof callback === "function") {
              callback();
            }
          });
        }

      }
    },

    clearFilterAll(groups) {
      for (let i = 0; i < groups.length; i++) {
        let group = groups[i];

        group.filtered = false;
        group.open = false;

        if (group.players) {
          for (let p = 0; p < group.players.data.length; p++) {
            let player = group.players.data[p];
            player.filtered = false;
          }
        }

        if (group.children && group.children.length > 0) {
          this.clearFilterAll(group.children);
        }

      }
    }
  }
}
</script>

<style scoped lang="scss">
.header {
    margin: 4em 1em 1em;

    .header-name {
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
    }
}

.cols {
    display: flex;

    .col {
        flex: 1;
        flex-shrink: 0;

        .search-wrap {
            height: 2.5em;
            display: flex;

            .search {
                flex: 1;
                margin-left: 1em;
            }

            select {
                padding: 0.85em;
                box-sizing: border-box;
                border: none;
                box-shadow: $shading;
            }
        }
    }
}

.group {
    border-bottom: 1px solid #eee;
    padding: 0.5em 0 0.5em 0.5em;

    &:last-child {
        border-bottom: 0;
    }
}

</style>