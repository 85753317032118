<template>
    <div class="options" v-if="item">
        <div class="header">
            <div class="btns">
                <div class="back btn">
                    <sc-button @click="back">Back</sc-button>
                </div>
                <div class="next btn">
                    <sc-button @click="next">Next</sc-button>
                </div>
            </div>
            <div class="header-name">Add a News Feed</div>
        </div>
        <div class="items">
            <div class="item" v-for="item in items" @click="select(item)"
                 :class="{active:form.news_feed.id === item.id}">
                <div class="image">
                    <img :src="imageURL(item)"/>
                    <awesome-icon class="check" icon="check" v-show="form.news_feed.id === item.id"></awesome-icon>
                </div>
                <div class="title">
                    {{ item.attributes.title }}
                </div>
            </div>
        </div>
    </div>


</template>

<script>
export default {
  name: "template-feeds",

  props: {
    form: {}
  },

  emits: ['back', 'next', 'update:form'],

  data() {
    return {
      tab: 2,
      item: null,
      items: []
    }
  },

  computed: {},

  mounted() {
    this.item = this.form;
    this.fetch();
  },

  methods: {
    select(item) {
      if (this.item.news_feed.id === item.id) {
        this.item.news_feed = {};
      } else {
        this.item.news_feed = item;
      }
      this.$emit('update:form', this.item)
    },

    fetch() {
      this.$talker.api('templates/news-feeds')
        .then(res => {
          this.items = res.data.data;
        })
    },

    next() {
      this.$router.push({
        query: {
          tab: this.tab + 1
        }
      })
    },

    back() {
      this.$router.push({
        query: {
          tab: this.tab - 1
        }
      })
    },

    imageURL(item) {
      return require('@/img/' + (item.attributes.media_src.substring(4)))
    }
  }
}
</script>

<style scoped lang="scss">
.header {
    margin: 2em 32px 3em;

    .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 545px;
        margin: auto;
    }

    .header-name {
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
        margin-top: 1em;
    }
}

.items {
    display: flex;
    justify-content: center;

    .item {
        text-align: center;
        cursor: pointer;

        .image {
            width: 8em;
            height: 8em;
            border: 1px solid #ccc;
            margin: 0.5em;
            padding: 0.2em;
            background: #fff;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .check {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 4em;
                color: #adff2f;
            }
        }
    }
}
</style>