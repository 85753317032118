<template>
    <div class="options">
        <div class="header">
            <div class="header-name">Assign Players</div>
        </div>
        <div class="cols">
            <div class="col" style="flex:2;">
                <div class="search pdw" style="padding-right:24px;padding-top:12px;">
                    <sc-search @searched="search" placeholder="search players ..."></sc-search>
                </div>
                <!--                <sc-table :table="table" @view="selectPlayer" style="padding-right:8px;padding-top:14px;"></sc-table>-->
                <div class="players">
                    <div class="player" v-for="row in table.rows()" @click="selectPlayer(row)"
                         :class="{selected:hasPlayer(row)}">
                        <div class="name">{{ row.attributes.name }}</div>
                        <div class="status">Status: {{ row.attributes.active }}</div>
                        <div class="channel" v-if="row.channel">{{ row.channel.data.attributes.name }}</div>
                        <div class="icon">
                            <awesome-icon icon="mobile-alt"/>
                        </div>
                        <div class="check">
                            <awesome-icon icon="check"/>
                        </div>
                    </div>
                </div>
                <sc-paginate class="pagination panel" :table="table"></sc-paginate>
            </div>
            <div class="col" style="flex:1;">
                <sc-table :table="tableSelected" @view="removePlayer" style="padding-left:8px;padding-top:14px;">

                    <template #column-name="{row}">

                        <div class="selected-player">

                            <div class="name">
                                {{ row.attributes.name }}
                            </div>

                            <div class="add" @click="removePlayer(row)">
                                <div class="btn-txt">remove</div>
                                <awesome-icon class="icon" icon="times"/>
                            </div>
                        </div>
                    </template>

                </sc-table>
            </div>
        </div>
    </div>


</template>

<script>
import ScSearch from "@/components/sc-search";

export default {
  name: "template-players",
  components: {ScSearch},
  props: {
    form: {}
  },

  emits: ['update:form'],

  data() {
    return {
      items: [],
      table: this.$stable.createTable({
        name: 'All players',
        url: 'players',
        columns: {
          name: {}
        }
      }),
      tableSelected: this.$stable.createTable({
        name: 'players selected',
        sortBy: 'name',
        columns: {
          name: {
            target: 'attributes.name',
            fill: true,
          }
        }
      })
    }
  },

  computed: {},

  mounted() {
    this.table.fetch();
    this.tableSelected.data = this.form.players;
    this.tableSelected.hasData = true;
    this.tableSelected.prefetch();

  },

  methods: {

    selectPlayer(player) {
      let index = this.tableSelected.data.findIndex(x => x.id === player.id)
      if (index === -1) {
        this.tableSelected.data.push(player);
        this.tableSelected.prefetch();
      } else {
        this.tableSelected.data.splice(index, 1);
      }
    },

    removePlayer(player) {
      let index = this.tableSelected.data.findIndex(x => x.id === player.id)
      if (index !== -1) {
        this.tableSelected.data.splice(index, 1);
      }
    },

    hasPlayer(player) {
      let index = this.tableSelected.data.findIndex(x => x.id === player.id)
      return (index !== -1);
    },

    search(search) {
      this.table.fetchArguments = () => {
        return `&filters=${JSON.stringify({query: search})}`;
      }
      this.table.page = 1;
      this.table.fetch();
    },
  }
}
</script>

<style scoped lang="scss">
.header {
    margin: 0em 1em 1em;

    .header-name {
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
        margin-top: 1em;
    }
}

.cols {
    display: flex;

    .col {
        flex: 1;
        flex-shrink: 0;

        .search {
            height: 2.5em;
        }
    }
}

.players {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(140px, 1fr) );
    padding: 24px 24px 0 32px;
    grid-gap: 1em;

    .player {
        background: #fff;
        border-radius: 0.3em;
        padding: 0.4em 0.2em;
        font-size: 0.8em;
        color: #a94442;
        text-align: center;
        line-height: 1.4em;
        position: relative;
        cursor: pointer;

        .name {
            font-weight: 700;
        }

        .icon {
            font-size: 2em;
            margin-top: 0.2em;
        }

        .check {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 4em;
            color: #adff2f;
            pointer-events: none;
            opacity: 0;
        }

        &:hover {
            box-shadow: 0 0 0 2px $color-primary;
        }

        &.selected {

            .check {
                opacity: 1;
            }
        }


    }
}

.pagination {
    background: #fff;
    margin: 0 24px 0 32px;
}

.selected-player {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7em 0;

    .open, .add {
        padding: 0.4em 0.5em;
        margin-left: 0.5em;

        background: $color-primary;
        color: #fff;
        border-radius: 0.2em;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-txt {
            font-weight: 800;
            text-transform: uppercase;
            font-size: 0.6em;
            margin-right: 0.7em;
            line-height: 1em;

            @media screen and (max-width: 1000px) {
                display: none;
            }
        }

        .icon {
            font-size: 0.8em;
        }

        &:hover {
            background: $color-primary-hover;
        }
    }
}


</style>