<template>
    <div class="tabs">
        <template v-for="(tab,index) in tabs">
            <div class="tab" :class="{active:active === (index)}"
                 @click="activate(index)">
                <div class="tab-inner" :style="{background:tab.color}">
                    <awesome-icon :icon="tab.icon"></awesome-icon>
                </div>
            </div>
            <div class="separator" v-if="index < (tabs.length - 1)"></div>
        </template>
    </div>
</template>

<script>
export default {
  name: "template-tabs",

  props: {
    active: {
      type: Number,
      default: 1
    },
    form: {}
  },

  emits: ['activateTab'],

  data() {
    return {
      tabs: [
        {icon: ['far', 'object-group'], color: '#026302'},
        {icon: 'exclamation', color: '#317087', guard: 'priority'},
        {icon: ['far', 'newspaper'], color: '#ffb61c'},
        {icon: 'sort-numeric-down-alt', color: '#1b7dbc'},
        {icon: 'mobile-alt', color: '#317087'},
        {icon: 'font', color: '#e94c3b'},
        {icon: 'check', color: '#22b08a'},
      ]
    }
  },

  methods: {
    activate(index) {

      if (index > 0 && !this.form.type.id) {
        this.$notify.error('Please select a message template to continue')
        return;
      }

      if (index > 1 && !this.form.priority.id) {
        this.$notify.error('Please select a message priority to continue')
        return;
      }

      if (index > 5 && !this.form.title && this.form.type.id !== 4) {
        this.$notify.error('Please enter a title to continue')
        return;
      }

      if (index > 4 && (this.form.players.length === 0 && this.form.player_groups.length === 0)) {
        this.$notify.error('Please assign a Player or Player Group to continue')
        return;
      }

      this.$router.push({
        query: {
          tab: index
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;

    .tab {
        width: 2em;
        height: 2em;
        position: relative;
        cursor: pointer;

        .tab-inner {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            color: #fff;
            width: 2em;
            height: 2em;
        }

        &.active .tab-inner {
            font-size: 1.5em;
        }
    }

    .separator {
        width: 3em;
        background: #333;
        height: 3px;
    }
}
</style>