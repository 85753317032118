<template>
    <div class="options">
        <div class="header">
            <div class="btns">
                <div class="back btn">
                    <sc-button @click="back">Back</sc-button>
                </div>
                <div class="next btn">
                    <sc-button @click="next">Next</sc-button>
                </div>
            </div>
        </div>
        <template-players :form="form"></template-players>
        <template-playergroups :allgroups="groups" :form.sync="form" v-if="groups"></template-playergroups>
        <div class="loading" v-else>
            Loading Player Groups
        </div>
    </div>


</template>

<script>

import TemplatePlayers from "@/views/app/templates/components/template-players";
import TemplatePlayergroups from "@/views/app/templates/components/template-playergroups";

export default {
  name: "template-select",
  components: {TemplatePlayergroups, TemplatePlayers},
  props: {
    form: {},
    groups: {}
  },

  data() {
    return {
      tab: 4,
    }
  },

  emits: ['back', 'next', 'update:form'],

  methods: {

    next() {
      if (this.form.players.length === 0 && this.form.player_groups.length === 0) {
        this.$notify.error('Please assign a Player or Player Group to continue')
        return;
      }
      this.$router.push({
        query: {
          tab: this.tab + 1
        }
      })
    },

    back() {
      this.$router.push({
        query: {
          tab: this.tab - 1
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.header {
    margin: 2em 32px 0em;

    .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 545px;
        margin: auto;
    }
}

.loading {
    margin: 2em 32px;
    box-sizing: border-box;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    padding: 3em 0;
    border-radius: 0.3em;
}
</style>