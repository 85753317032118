<template>
    <div class="wizard-container">

        <div class="wizard">
            <template-tabs :form.sync="form" :active="tab" @activateTab="activateTab"></template-tabs>
            <template-type
                    :form.sync="form" v-if="tab === 0"></template-type>
            <template-priority
                    :form.sync="form" v-if="tab === 1"></template-priority>
            <template-feeds
                    :form.sync="form" v-if="tab === 2"></template-feeds>
            <template-ccil
                    :form.sync="form" v-if="tab === 3"></template-ccil>
            <template-select :groups="player_groups" :form.sync="form"
                             v-if="tab === 4 "></template-select>
            <template-texts
                    :form.sync="form" v-if="tab === 5"></template-texts>
            <template-publish :groups="player_groups"
                              :form.sync="form" v-if="tab === 6 "
                              @publish="publish"></template-publish>
        </div>
        <div class="preview">
            <template-preview :form="form" @rotate="rotate"></template-preview>
        </div>

    </div>
</template>

<script>
import moment from 'moment-timezone'
import TemplateTabs from "@/views/app/templates/components/template-tabs";
import TemplateType from "@/views/app/templates/components/template-type";
import TemplatePreview from "@/views/app/templates/components/template-preview";
import TemplatePriority from "@/views/app/templates/components/template-priority";
import TemplateFeeds from "@/views/app/templates/components/template-feeds";
import TemplateCcil from "@/views/app/templates/components/template-ccil";
import TemplateSelect from "@/views/app/templates/components/template-select";
import TemplateTexts from "@/views/app/templates/components/template-texts";
import TemplatePublish from "@/views/app/templates/components/template-publish";

export default {
  name: "template-wizard",
  components: {
    TemplatePublish,
    TemplateTexts,
    TemplateSelect,
    TemplateCcil,
    TemplateFeeds,
    TemplatePriority,
    TemplatePreview,
    TemplateType,
    TemplateTabs
  },
  props: {
    item: {},
    clone: {
      default: false,
    }
  },

  data() {
    return {
      // tab: 1,

      form: {
        ccil_reference: null,
        title: '',
        text_1: '',
        image_1: null,
        bg_image: null,
        text_only: true,
        side_image_left: '',
        side_image_right: '',
        dir: 'left',
        landscape: true,
        start_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        is_template: false,

        type: {},
        priority: {},
        news_feed: {},

        players: [],
        player_groups: [],
      },

      player_groups: null,

    }
  },

  computed: {
    tab() {
      return parseInt(this.$route.query.tab);
    }
  },

  created() {
    if (this.item) {
      this.form.ccil_reference = this.item.data.attributes.ccil_reference;
      this.form.title = this.item.data.attributes.title;
      this.form.text_1 = this.item.data.attributes.text_1;

      this.form.side_image_left = this.item.data.attributes.side_image_left;
      this.form.side_image_right = this.item.data.attributes.side_image_right;
      this.form.bg_image = this.item.data.attributes.bg_image;

      this.form.type = (this.item.data.template_type) ? this.item.data.template_type.data : {};
      this.form.priority = (this.item.data.template_priority) ? this.item.data.template_priority.data : {};
      this.form.news_feed = (this.item.data.news_feed) ? this.item.data.news_feed.data : {};

      if (this.clone !== true) {
        this.form.start_date = this.item.data.attributes.start_date;
        this.form.end_date = this.item.data.attributes.end_date;
        this.form.players = (this.item.data.players) ? this.item.data.players.data : [];
        this.form.player_groups = (this.item.data.player_groups) ? this.item.data.player_groups.data.flatMap(x => x.id) : [];
      }

    } else {

      if (this.tab !== 0) {
        this.$router.push({
          query: {
            tab: 0
          }
        })
      }
    }
    this.fetchGroups();
  },

  methods: {

    fetchGroups() {
      this.$talker.api('player-groups?players=true')
        .then(res => {
          this.player_groups = res.data.data;
        })
        .catch(err => {
          this.$notify.error('Failed to get player groups')
        })
    },

    rotate() {
      this.form.landscape = !this.form.landscape;
    },

    activateTab(tab) {
      this.tab = tab;
    },

    publish() {

      let payload = {};

      payload.ccil_reference = this.form.ccil_reference;
      payload.title = this.form.title;
      payload.text_1 = this.form.text_1;
      payload.start_date = this.form.start_date;
      payload.end_date = this.form.end_date;
      payload.side_image_left = this.form.side_image_left;
      payload.side_image_right = this.form.side_image_right;
      payload.bg_image = this.form.bg_image;
      payload.dir = this.form.dir;
      payload.landscape = this.form.landscape;
      payload.is_template = this.form.is_template;
      payload.text_only = this.form.text_only;

      payload.type_id = this.form.type.id;
      payload.priority_id = this.form.priority.id;
      payload.news_feed_id = this.form.news_feed.id;

      payload.players = this.form.players.map(x => x.id)
      payload.player_groups = this.form.player_groups;

      if (this.item && this.clone === false) {
        this.$talker.api.put(`templates/${this.item.data.id}`, payload)
          .then(res => {
            this.$notify.success('Message updated')
            this.$router.push('/app/dashboard')
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.errors) {
              this.$notify.error(err.response.data.errors)
            } else if (err.response && err.response.data && err.response.data.message) {
              this.$notify.error(err.response.data.message)
            } else {
              this.$notify.error('Failed to submit')
            }

          })
      } else {
        this.$talker.api.post(`templates`, payload)
          .then(res => {
            this.$notify.success('Message created')
            this.$router.push('/app/dashboard')
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.errors) {
              this.$notify.error(err.response.data.errors)
            } else if (err.response && err.response.data && err.response.data.message) {
              this.$notify.error(err.response.data.message)
            } else {
              this.$notify.error('Failed to submit')
            }

          })
      }

    }
  }
}
</script>

<style scoped lang="scss">
.wizard-container {
    display: flex;

    .wizard {
        flex: 2;
    }

    .preview {
        flex: 1;
    }
}
</style>