<template>
    <div>
        <div class="title">
            <slot></slot>
        </div>
        <div class="datepicker">
            <datepicker v-if="date" v-model="date" format="DD-MM-YYYY" :clearable="false"
                        @change="select()"></datepicker>
        </div>
        <div class="time-picker">
            <template-time v-if="time" :time.sync="time" @update:time="select()"></template-time>
        </div>
    </div>
</template>

<script>
import datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone'
import TemplateTime from "@/views/app/templates/components/template-time";

export default {
  name: "template-datetime",
  components: {
    TemplateTime,
    datepicker
  },

  props: {
    value: {required: true,}
  },

  emits: ['update:value'],

  data() {
    return {
      date: null,
      time: null,
      ignoreStartDate: moment().add(1, 'days').format('YYYY/MM/DD'),
    }
  },

  computed: {},

  watch: {
    date() {
      this.select();
    }
  },

  mounted() {
    this.date = new Date(this.value.split(' ')[0]);
    this.time = this.value.split(' ')[1];
  },

  methods: {
    select() {
      this.$emit('update:value', this.dateToYMD(this.date) + ' ' + this.time);
    },
    dateToYMD(date) {
      if (!date) return
      let d = date.getDate();
      let m = date.getMonth() + 1; //Month from 0 to 11
      let y = date.getFullYear();
      return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    },

  }
}
</script>

<style>
.datepicker {
    border: none;
    border-radius: 0.3em;
    text-align: center;
}

.datepicker input {
    border: 1px solid #111;
    text-align: center;
}
</style>

<style scoped lang="scss">


.title {
    font-weight: 600;
    font-size: 0.8em;
    text-align: center;
    margin-bottom: 0.2em;
}
</style>

