<template>
    <div class="options" v-if="item">
        <div class="header">
            <div class="btns">
                <div class="back btn">
                    <sc-button @click="back">Back</sc-button>
                </div>
                <div class="next btn">
                    <sc-button @click="next">Next</sc-button>
                </div>
            </div>
            <div class="header-name" v-if="item.type.id !== 4">Choose text for your screen</div>
            <div class="header-name" v-else>Choose image for your screen</div>
        </div>
        <div class="items-container" v-if="item.type.id !== 4">
            <div class="items">
                <div class="item-desc">Type in your text for the template</div>
                <input v-model="item.title" placeholder="Enter title text" maxlength="90"/>
                <div class="char-left" v-if="item.title.length > 0">{{ 90 - item.title.length }} characters left</div>
            </div>
            <div class="toggles">
                <div class="toggle" @click="item.text_only = true">
                    <div class="toggle-status">
                        <awesome-icon class="check" icon="check" v-show="item.text_only === true"/>
                    </div>
                    <div class="toggle-name">Text description</div>
                </div>
                <div class="toggle" @click="item.text_only = false">
                    <div class="toggle-status">
                        <awesome-icon class="check" icon="check" v-show="item.text_only !== true"/>
                    </div>
                    <div class="toggle-name">Image description</div>
                </div>
            </div>
            <template v-if="item.text_only === true">
                <div class="items">
                    <textarea v-model="item.text_1" placeholder="Enter paragraph text" style="min-width:200px"/>
                </div>
                <div class="media-item">
                    <div class="media-preview" v-if="item.side_image_left">
                        <img :src="imageURL(item.side_image_left)"/>
                    </div>
                    <nr-image-picker :field.sync="item.side_image_left">Choose a left media item</nr-image-picker>
                </div>
                <div class="media-item">
                    <div class="media-preview" v-if="item.side_image_right">
                        <img :src="imageURL(item.side_image_right)"/>
                    </div>
                    <nr-image-picker :field.sync="item.side_image_right">Choose a right media item</nr-image-picker>
                </div>
            </template>
            <template v-else>
                <div class="media-item">
                    <div class="media-preview" v-if="item.image_1">
                        <img :src="imageURL(item.image_1)"/>
                    </div>
                    <nr-image-picker :field.sync="item.image_1">Choose a media item</nr-image-picker>
                </div>
            </template>
        </div>
        <div class="items-container" v-if="item.type.id === 4">
            <div class="media-item">
                <div class="media-preview" v-if="item.bg_image">
                    <img :src="imageURL(item.bg_image)"/>
                </div>
                <nr-image-picker :field.sync="item.bg_image">Choose a media item</nr-image-picker>
            </div>
        </div>

    </div>


</template>

<script>
import NrImagePicker from "@/components/common/nr-image-picker";

export default {
  name: "template-texts",
  components: {NrImagePicker},
  props: {
    form: {}
  },

  emits: ['back', 'next', 'update:form'],

  data() {
    return {
      tab: 5,
      item: null,
    }
  },

  computed: {},

  mounted() {
    this.item = this.form;
  },

  methods: {
    next() {
      if (!this.item.title && this.form.type.id !== 4) {
        this.$notify.error('Please enter a title to continue')
        return;
      }
      this.$emit('update:form', this.item)
      this.$router.push({
        query: {
          tab: this.tab + 1
        }
      })
    },

    back() {
      this.$emit('update:form', this.item)
      this.$router.push({
        query: {
          tab: this.tab - 1
        }
      })
    },

    imageURL(file) {
      return process.env.VUE_APP_AWS_URL + file
    }
  }
}
</script>

<style scoped lang="scss">
.items-container {
    margin: 0 auto;
    padding: 0 2em;
    max-width: 800px;
}

.header {
    margin: 2em 32px 3em;

    .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 545px;
        margin: auto;
    }

    .header-name {
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
        margin-top: 1em;
    }
}

.item-desc {
    text-align: center;
}

.char-left {
    text-align: center;
    font-size: 0.9em;
    color: #555;
    margin: 0.5em 0 2em;
}

.media-item {
    margin: 2em 0;
    text-align: center;

    .media-preview {
        width: 100%;
        max-height: 10em;
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 0.5em;

        img {
            object-fit: contain;
            max-width: 100%;
        }
    }
}

.toggles {
    display: flex;
    margin: 1em;
    justify-content: center;

    .toggle {
        display: flex;
        align-items: center;
        margin: 0 1em;
        padding: 0 1em;
        cursor: pointer;

        .toggle-status {
            width: 1.5em;
            height: 1.5em;
            border: 2px solid $color-primary;
            display: flex;
            margin-right: 0.4em;
            font-size: 0.7em;

            .check {
                margin: auto;
            }
        }

        .toggle-name {
            font-weight: 600;
            font-size: 0.8em;
        }
    }
}

</style>