<template>
    <div class="options" v-if="item">
        <div class="header">
            <div class="btns">
                <div class="back btn">

                </div>
                <div class="next btn">
                    <sc-button @click="next">Next</sc-button>
                </div>
            </div>
            <div class="header-name">Select Operational Message Template</div>
        </div>
        <div class="toggle" @click="item.dir = (item.dir === 'left') ? 'right' : 'left'">
            <div class="toggle-status">
                <awesome-icon class="check" icon="check" v-show="item.dir === 'right'"/>
            </div>
            <div class="toggle-name">Swap image position</div>
        </div>
        <div class="items">

            <div class="item" v-for="item in items" @click="select(item)" :class="{active:form.type.id === item.id}">
                <div class="image">
                    <img :src="imageURL(item)"/>
                    <!--                    <img src="@/img/template-types/engineering-landscape.jpg"/>-->
                    <awesome-icon class="check" icon="check" v-show="form.type.id === item.id"></awesome-icon>
                </div>
                <div class="title">
                    {{ item.attributes.title }}
                </div>
            </div>
        </div>
    </div>


</template>

<script>
export default {
  name: "template-type",

  props: {
    form: {}
  },

  emits: ['back', 'next', 'update:form'],

  data() {
    return {
      tab: 0,
      item: null,
      items: []
    }
  },

  computed: {},

  mounted() {
    this.item = this.form;
    this.fetch();
  },

  methods: {
    select(item) {
      if (this.item.type === item) {
        this.item.type = {};
      } else {
        this.item.type = item;
      }
      this.$emit('update:form', this.item)
    },

    fetch() {
      this.$talker.api('templates/types')
        .then(res => {
          this.items = res.data.data;
        })
    },

    next() {
      if (!this.item.type.id) {
        this.$notify.error('Please select a message template to continue')
        return;
      }
      this.$router.push({
        query: {
          tab: this.tab + 1
        }
      })
    },

    back() {
      this.$router.push({
        query: {
          tab: this.tab - 1
        }
      })
    },

    imageURL(item) {
      return require('@/img/' + (item.attributes.media_portrait_src.substring(4)))
    }
  }
}
</script>

<style scoped lang="scss">
.header {
    margin: 2em 32px 3em;

    .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 545px;
        margin: auto;
    }

    .header-name {
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
        margin-top: 1em;
    }
}

.items {
    display: flex;
    justify-content: center;

    .item {
        text-align: center;
        cursor: pointer;

        .image {
            width: 8em;
            height: 8em;
            border: 1px solid #ccc;
            margin: 0.5em;
            padding: 0.2em;
            background: #fff;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .check {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 4em;
                color: #adff2f;
            }
        }
    }
}

.toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 1em;
    padding: 0 1em;
    cursor: pointer;

    .toggle-status {
        width: 1.5em;
        height: 1.5em;
        border: 2px solid $color-primary;
        display: flex;
        margin-right: 0.4em;
        font-size: 0.7em;

        .check {
            margin: auto;
        }
    }

    .toggle-name {
        font-weight: 600;
        font-size: 0.8em;
    }
}
</style>