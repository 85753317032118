<template>
    <div class="time-picker">
        <template-time-unit class="time" max="23"
                            :time.sync="hour"
                            @changed="select()"
                            @done="$refs.minute.$refs.time.focus()"></template-time-unit>
        :
        <template-time-unit class="time" ref="minute"
                            :time.sync="minute"
                            @changed="select()"
                            @done="$refs.second.$refs.time.focus()"
                            @cascadePlus="cascadePlus('hour', 23)"
                            @cascadeMinus="cascadeMinus('hour', 23)"></template-time-unit>
        <template v-if="!withoutSeconds">
        :
        <template-time-unit class="time" ref="second"
                            :time.sync="second"
                            @changed="select()"
                            @cascadePlus="cascadePlus('minute')"
                            @cascadeMinus="cascadeMinus('minute')"
        ></template-time-unit>
        </template>
    </div>
</template>

<script>
import TemplateTimeUnit from "@/views/app/templates/components/template-time-unit";

export default {
  name: "template-time",
  components: {TemplateTimeUnit},

  props: {
    time: {required: true},
    withoutSeconds: {required: false, default: false}
  },

  data() {
    return {
      hour: null,
      minute: null,
      second: null,
    }
  },

  mounted() {
    this.hour = this.time.split(':')[0];
    this.minute = this.time.split(':')[1];
    this.second = this.withoutSeconds ? 0 : this.time.split(':')[2];
  },

  methods: {
    select() {
      this.$emit('update:time', this.getTime());
    },
    getTime() {
      let time =`${this.hour}:${this.minute}:${this.second}`;
      if (this.withoutSeconds) time = `${this.hour}:${this.minute}`;
      return time;
    },
    cascadePlus(target, max = 59) {
      let val = this[target];
      if (val.length > 1 && val.charAt(0) === '0') {
        val = parseInt(val.charAt(1))
      } else {
        val = parseInt(val);
      }
      if (val < parseInt(max)) {
        val++
      } else {
        val = 0;
        if (target === 'minute') {
          this.cascadePlus('hour', 23)
        }
      }
      if (val < 10) {
        val = '0' + val
      }
      this[target] = String(val);
    },
    cascadeMinus(target, max = 59) {
      let val = this[target];
      if (val.length > 1 && val.charAt(0) === '0') {
        val = parseInt(val.charAt(1))
      } else {
        val = parseInt(val);
      }
      if (val > 0) {
        val--
      } else {
        val = max;
        if (target === 'minute') {
          this.cascadeMinus('hour', 23)
        }
      }
      if (val < 10) {
        val = '0' + val
      }
      this[target] = String(val);
    }
  }
}
</script>

<style scoped lang="scss">
.time-picker {
    display: flex;
    align-items: center;
    justify-content: center;

    .time {
        margin: 0 0.3em;
    }
}
</style>