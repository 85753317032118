<template>
    <div class="time">
        <button class="btn up" @click="plus" tabindex="-1">
            <awesome-icon icon="chevron-up"></awesome-icon>
        </button>
        <input v-model="val" @focus="$event.target.select()" ref="time" @input="input" @blur="blur"
               maxlength="2"/>
        <button class="btn down" @click="minus" tabindex="-1">
            <awesome-icon icon="chevron-down"></awesome-icon>
        </button>
    </div>
</template>

<script>
export default {
  name: "template-time-unit",

  props: {
    time: {
      required: true,
    },
    max: {
      type: String,
      default: '59',
    },
  },

  data() {
    return {
      val: null
    }
  },

  emits: ['update:time', 'done', 'changed', 'cascadePlus', 'cascadeMinus'],

  computed: {},

  mounted() {
    this.val = this.time;
  },

  watch: {
    time() {
      this.val = this.time;
    }
  },

  methods: {
    input() {
      if (this.val.length > 1) {
        this.$emit('done')
      }
    },
    blur() {
      if (this.val.length === 1) {
        this.val = '0' + this.val
      }
      if (parseInt(this.val.charAt(0)) > parseInt(this.max.charAt(0)) ||
        (parseInt(this.val.charAt(0)) === parseInt(this.max.charAt(0)) && parseInt(this.val.charAt(1)) > parseInt(this.max.charAt(1)))) {
        this.val = this.max;
      }
      this.changed();
    },
    changed() {
      this.$emit('update:time', this.val);
      this.$emit('changed');
    },
    plus() {
      let val = this.val;
      if (val.length > 1 && val.charAt(0) === '0') {
        val = parseInt(val.charAt(1))
      } else {
        val = parseInt(val);
      }
      if (val < parseInt(this.max)) {
        val++
      } else {
        val = 0;
        this.$emit('cascadePlus')
      }
      this.val = String(val);
      this.blur();
    },
    minus() {
      let val = this.val;
      if (val.length > 1 && val.charAt(0) === '0') {
        val = parseInt(val.charAt(1))
      } else {
        val = parseInt(val);
      }
      if (val > 0) {
        val--
      } else {
        val = this.max
        this.$emit('cascadeMinus')
      }
      this.val = String(val);
      this.blur();
    }
  }
}
</script>

<style scoped lang="scss">
.time {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        width: 4em;
        min-width: 4em;
        text-align: center;
        border: 1px solid #005172;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .btn {
        border: none;
        background: none;
        margin: 0.5em 0;
        padding: 0.3em 1em;
        color: #005172;
        cursor: pointer;
    }
}
</style>