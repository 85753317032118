<template>
    <div v-if="item">
        <div class="header">
            <div class="btns">
                <div class="back btn">
                    <sc-button @click="back">Back</sc-button>
                </div>
                <div class="next btn">
                </div>
            </div>
            <div class="header-name">Publish</div>
        </div>
        <div class="options">
            <div class="panel dates">
                <template-datetime class="date" v-if="item.start_date" :value.sync="item.start_date">Start date
                </template-datetime>
                <template-datetime class="date" v-if="item.end_date" :value.sync="item.end_date">End date
                </template-datetime>
            </div>
            <div class="panel type" @click="$router.push({query:{tab:0}})">
                <div class="panel-icon">
                    <div class="panel-icon-inner">
                        <awesome-icon :icon="['far', 'object-group']"></awesome-icon>
                    </div>
                </div>
                <div class="panel-content">
                    <div class="panel-title">
                        Template
                    </div>
                    <div class="panel-details">
                        <span v-if="item.type && item.type.attributes">{{ item.type.attributes.title }}</span>
                    </div>
                </div>
            </div>
            <div class="panel priority" @click="$router.push({query:{tab:1}})">
                <div class="panel-icon">
                    <div class="panel-icon-inner">
                        <awesome-icon icon="exclamation"></awesome-icon>
                    </div>
                </div>
                <div class="panel-content">
                    <div class="panel-title">
                        Priority
                    </div>
                    <div class="panel-details">
                        <span v-if="item.priority && item.priority.attributes">{{
                                item.priority.attributes.title
                            }}</span>
                    </div>
                </div>
            </div>
            <div class="panel news-feed" v-if="item.news_feed && item.news_feed.id"
                 @click="$router.push({query:{tab:2}})">
                <div class="panel-icon">
                    <div class="panel-icon-inner">
                        <awesome-icon :icon="['far', 'newspaper']"></awesome-icon>
                    </div>
                </div>
                <div class="panel-content">
                    <div class="panel-title">
                        News Feed
                    </div>
                    <div class="panel-details">
                        <span v-if="item.news_feed && item.news_feed.attributes">{{
                                item.news_feed.attributes.name
                            }}</span>
                    </div>
                </div>
            </div>
            <div class="panel ccil" v-if="item.ccil_reference" @click="$router.push({query:{tab:3}})">
                <div class="panel-icon">
                    <div class="panel-icon-inner">
                        <awesome-icon icon="sort-numeric-down-alt"></awesome-icon>
                    </div>
                </div>
                <div class="panel-content">
                    <div class="panel-title">
                        CCIL Reference
                    </div>
                    <div class="panel-details">
                        {{ item.ccil_reference }}
                    </div>
                </div>
            </div>
            <div class="panel title" @click="$router.push({query:{tab:5}})">
                <div class="panel-icon">
                    <div class="panel-icon-inner">
                        <awesome-icon icon="font" v-if="item.type && item.type.id !== 4"></awesome-icon>
                        <awesome-icon icon="image" v-else></awesome-icon>
                    </div>
                </div>
                <div class="panel-content" v-if="item.type && item.type.id !== 4">
                    <div class="panel-title">
                        Title
                    </div>
                    <div class="panel-details">
                        {{ item.title }}
                    </div>
                    <template v-if="item.text_only === true">
                        <div class="panel-title" style="margin-top:1em;" v-if="item.text_1">
                            Description
                        </div>
                        <div class="panel-details">
                            {{ item.text_1 }}
                        </div>
                        <template v-if="item.side_image_left">
                            <div class="panel-title" style="margin-top:1em;">
                                Left media item
                            </div>
                            <div class="panel-details">
                                <img :src="imageURL(item.side_image_left)">
                            </div>
                        </template>
                        <template v-if="item.side_image_right">
                            <div class="panel-title" style="margin-top:1em;">
                                Right media item
                            </div>
                            <div class="panel-details">
                                <img :src="imageURL(item.side_image_right)">
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="panel-title" style="margin-top:1em;">
                            Image description
                        </div>
                        <div class="panel-details">
                            <img :src="imageURL(item.image_1)">
                        </div>
                    </template>
                </div>
                <div class="panel-content" v-else>
                    <div class="panel-title">
                        Background image
                    </div>
                    <div class="panel-details">
                        <img :src="imageURL(item.bg_image)"/>
                    </div>
                </div>
            </div>
            <div class="panel players" @click="$router.push({query:{tab:4}})">
                <div class="panel-icon">
                    <div class="panel-icon-inner">
                        <awesome-icon icon="mobile-alt"></awesome-icon>
                    </div>
                </div>
                <div class="panel-content">
                    <div class="panel-title">
                        Players
                    </div>
                    <div class="panel-details">
                        <div class="player" v-for="player in item.players" v-if="item.players.length > 0">
                            {{ player.attributes.name }}
                        </div>
                        <div v-else>None</div>
                    </div>
                </div>
            </div>
            <div class="panel players" @click="$router.push({query:{tab:4}})">
                <template v-if="groups">
                    <div class="panel-icon">
                        <div class="panel-icon-inner">
                            <awesome-icon icon="mobile-alt"></awesome-icon>
                        </div>
                    </div>
                    <div class="panel-content" style="width:100%">
                        <div class="panel-title">
                            Player groups
                        </div>
                        <div class="panel-details">
                            <div class="player group" v-for="group in item.player_groups"
                                 v-if="item.player_groups.length > 0">
                                <template-playergroup @click.stop :id="group" :groups="groups"></template-playergroup>
                            </div>
                            <div v-else>None</div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="panel-icon">
                        <div class="panel-icon-inner">
                            <awesome-icon icon="mobile-alt"></awesome-icon>
                        </div>
                    </div>
                    <div class="panel-content" style="width:100%">
                        <div class="panel-title">
                            Player groups
                        </div>
                        <div class="panel-details">
                            Loading ...
                        </div>
                    </div>
                </template>
            </div>
            <div>
                <div class="toggle" @click="item.is_template = !item.is_template">
                    <div class="toggle-status">
                        <awesome-icon class="check" icon="check" v-show="item.is_template"/>
                    </div>
                    <div class="toggle-name">Save message to favourites</div>
                </div>
            </div>
            <div class="btns">
                <sc-button @click="$emit('publish')">Publish</sc-button>
            </div>
        </div>
    </div>


</template>

<script>
import TemplatePlayergroup from "@/views/app/templates/components/template-playergroup";
import TemplateDatetime from "@/views/app/templates/components/template-datetime";
import NrRadio from "@/components/common/nr-radio";

export default {
  name: "template-publish",
  components: {NrRadio, TemplateDatetime, TemplatePlayergroup},
  props: {
    form: {},
    groups: {}
  },

  emits: ['back', 'next', 'update:form', 'publish'],

  data() {
    return {
      tab: 6,
      item: null,
    }
  },

  computed: {},

  mounted() {
    this.item = this.form;
  },

  methods: {
    next() {
      this.$emit('update:form', this.item)
      this.$router.push({
        query: {
          tab: this.tab + 1
        }
      })
    },

    back() {
      this.$emit('update:form', this.item)
      this.$router.push({
        query: {
          tab: this.tab - 1
        }
      })
    },

    imageURL(file) {
      return process.env.VUE_APP_AWS_URL + file;
    }
  }
}
</script>

<style scoped lang="scss">
.header {
    margin: 2em 32px 1em;

    .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 545px;
        margin: auto;
    }

    .header-name {
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
        margin-top: 1em;
    }
}

.options {
    margin: 0 32px;
}

.dates {
    display: flex;
    justify-content: space-between;

    .date {
        flex: 1;
        margin: 1em 2em 0.5em;
    }
}

.panel {
    margin-bottom: 1em;
    display: flex;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 2px $color-primary;

        &.dates {
            cursor: initial;
            box-shadow: none;
        }
    }

    .panel-icon {
        width: 4em;
        height: 4em;
        margin: 0.5em;
        margin-right: 1em;
        flex-shrink: 0;

        .panel-icon-inner {
            border-radius: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: 2em;
            color: #fff;
        }
    }

    &.type .panel-icon-inner {
        background: #026302;
    }

    &.priority .panel-icon-inner {
        background: #317087;
    }

    &.news-feed .panel-icon-inner {
        background: #ffb61c;
    }

    &.ccil .panel-icon-inner {
        background: #1b7dbc;
    }

    &.title .panel-icon-inner {
        background: #e94c3b;
    }

    &.players .panel-icon-inner {
        background: #317087;
    }

    .panel-content {
        margin: 1em 0;

        .panel-title {
            font-weight: 600;
        }

        img {
            max-height: 4em;
        }
    }

    .group {
        margin: 0.5em 0;
    }
}

.btns {
    display: flex;
    justify-content: center;
}


.toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2em 1em;
    padding: 0 1em;
    cursor: pointer;

    .toggle-status {
        width: 1.5em;
        height: 1.5em;
        border: 2px solid $color-primary;
        display: flex;
        margin-right: 0.4em;
        font-size: 0.7em;

        .check {
            margin: auto;
        }
    }

    .toggle-name {
        font-weight: 600;
        font-size: 0.8em;
    }
}

</style>