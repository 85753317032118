<template>
    <div class="preview">
        <div class="panel">
            <div class="header">Screen preview</div>
            <div class="canvas-wrap">
                <div class="content canvas-container" ref="wrap" style="padding:0;"
                     :style="{height:canvasHeight + 'px',maxWidth:maxWidth + 'px'}">
                    <div class="canvas" v-if="item" :style="{fontSize:scale + 'em'}"
                         :class="{landscape:item.landscape,portrait:!item.landscape, iconRight:(item.dir === 'right')}">
                        <div class="background-image" v-if="item.type.id">
                            <img :src="backgroundImage"/>
                        </div>
                        <template v-if="item.type.id && item.type.id !== 4">
                            <div class="icon">
                                <img :src="icon"/>
                            </div>
                            <div class="title">
                                {{ title }}
                            </div>
                            <template v-if="item.text_only === true">
                                <div class="side-image left" v-if="item.side_image_left">
                                    <img :src="leftImage"/>
                                </div>
                                <div class="text" v-if="!item.side_image_left || !item.side_image_right"
                                     :class="{left:item.side_image_right, right:item.side_image_left}">
                                    {{ text }}
                                </div>
                                <div class="side-image right" v-if="item.side_image_right">
                                    <img :src="rightImage"/>
                                </div>
                            </template>
                            <template v-else>
                                <div class="description-image">
                                    <img :src="descriptionImage"/>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
            <div class="rotate-button">
                <sc-button icon="redo" @click="rotate">Rotate screen to
                    <template v-if="item && item.landscape">portrait</template>
                    <template v-else>landscape</template>
                </sc-button>
            </div>
        </div>
        <!--        <div class="panel" style="margin-top:1em">-->
        <!--            <div class="header">RAW data</div>-->
        <!--            <div class="content">-->
        <!--                <div class="form-raw">-->
        <!--                    {{ form }}-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>

export default {
  name: "template-preview",

  props: {
    form: {
      required: true
    }
  },

  emits: ['rotate'],

  data() {
    return {
      context: undefined,
      item: null,
      resizeTimeout: undefined,
      canvasWidth: 748,
      canvasHeight: 421,
      orientation: 'landscape',
      maxWidth: 500,
      ratio: 1,
      scale: 1,
      config: {
        title: {
          landscape: {
            x: 409,
            y: 125
          },
          portrait: {
            x: 86,
            y: 411
          },
          font: 96
        },
        body: {
          landscape: {
            x: 409,
            y: 463
          },
          portrait: {
            x: 90,
            y: 908
          },
          font: 80
        },
        icon: {
          landscape: {
            x: 40,
            y: 115
          },
          portrait: {
            x: 401,
            y: 115
          }
        }
      }
    }
  },

  computed: {
    backgroundImage() {
      if (this.item.type.attributes) {
        if (this.item.type.id !== 4) {
          if (this.item.landscape) {
            return require('@/img/' + (this.item.type.attributes.media_landscape_src.substring(4)))
          } else {
            return require('@/img/' + (this.item.type.attributes.media_portrait_src.substring(4)))
          }
        } else {
          return process.env.VUE_APP_AWS_URL + this.item.bg_image;
        }
      }
    },
    icon() {
      if (this.item.type.attributes) {
        if (this.item.type.id !== 4) {
          return require('@/img/templates/' + (this.item.type.attributes.title.toLowerCase()) + '.png')
        }
      }
    },
    title() {
      if (this.item.title) {
        return this.item.title;
      }
    },
    text() {
      if (this.item.text_1) {
        return this.item.text_1;
      }
    },
    descriptionImage() {
      if (this.item.image_1) {
        return process.env.VUE_APP_AWS_URL + this.item.image_1;
      }
    },
    leftImage() {
      if (this.item.side_image_left) {
        return process.env.VUE_APP_AWS_URL + this.item.side_image_left;
      }
    },
    rightImage() {
      if (this.item.side_image_right) {
        return process.env.VUE_APP_AWS_URL + this.item.side_image_right;
      }
    },
  },

  mounted() {
    this.item = this.form;
    this.orientation = (this.item.landscape === true) ? 'landscape' : 'portrait';
    this.scale = this.$refs.wrap.offsetWidth / this.canvasWidth;
    this.ratio = (this.item.landscape === true) ? this.$refs.wrap.offsetWidth / 1920 : this.$refs.wrap.offsetWidth / 1080
    this.resize();

    addEventListener('resize', this.resize)
  },

  beforeUnmount() {
    removeEventListener('resize', this.resize)
  },

  watch: {
    form: {
      handler(newVal, oldVal) {
        this.item = newVal;
        this.resize();
      },
      deep: true
    }
  },

  methods: {

    resize() {
      if (!this.item) return;
      this.maxWidth = (this.item.landscape === true) ? 500 : 300;

      // wait for width to be applied before processing new values
      this.$nextTick(() => {
        this.scale = this.$refs.wrap.offsetWidth / this.canvasWidth;
        this.ratio = (this.item.landscape === true) ? this.$refs.wrap.offsetWidth / 1920 : this.$refs.wrap.offsetWidth / 1080
        this.canvasHeight = (this.item.landscape === true) ? this.ratio * 1080 : this.ratio * 1920;
      })

    },

    rotate() {
      this.$emit('rotate')
    }

  }
}
</script>

<style scoped lang="scss">
.preview {
    margin-top: 1em;
    margin-right: 1em;

    .panel {

        .canvas-wrap {
            margin: 1em;

            .canvas-container {
                margin: auto;
                border: 1px solid #555;
                position: relative;
                overflow: hidden;

                //pointer-events: none;

                &.portrait {
                    max-width: 300px;
                }

                .canvas {
                    position: relative;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .icon, .title, .background-image, .text, .description-image, .side-image {
                        position: absolute;
                    }

                    .side-image {
                        img {
                            object-fit: contain;
                        }
                    }

                    .background-image {
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;

                        img {
                            object-fit: contain;
                        }
                    }

                    .title {
                        color: #fff;
                        font-weight: 800;
                        line-height: 1.2em;
                    }

                    .text {
                        color: #fff;
                        font-weight: 700;
                        line-height: 1.1em;
                    }

                    &.landscape {


                        .icon {
                            left: 2.7%;
                            top: 10%;
                            width: 14%;
                        }

                        .title {
                            left: 21%;
                            top: 11%;
                            bottom: 0;
                            right: 0;
                            font-size: 2.35em;
                        }

                        .text {
                            left: 21%;
                            top: 42%;
                            right: 0;
                            font-size: 2em;

                            &.right {
                                left: 50%;
                            }

                            &.left {
                                left: 1%;
                                width: 50%;
                            }
                        }

                        &.iconRight {
                            .icon {
                                left: 85%;
                            }

                            .title {
                                left: 2%;
                                right: 18%;
                            }

                            .text {
                                left: 2%;
                                right: 18%;

                                &.right {
                                    left: 50%;
                                    right: 1%;
                                }

                                &.left {
                                    left: 1%;
                                    width: 50%;
                                }
                            }
                        }

                        .side-image {
                            left: 2.5%;
                            top: 42%;
                            bottom: 12%;
                            width: 45%;

                            &.right {
                                left: 52.5%
                            }
                        }

                        .description-image {
                            left: 27.5%;
                            top: 42%;
                            bottom: 12%;
                            width: 45%;
                        }

                        .background-image {
                            img {
                                object-fit: cover;
                            }
                        }
                    }

                    &.portrait {
                        .icon {
                            left: 37%;
                            top: 6%;
                            width: 26%;
                        }

                        .title {
                            top: 21%;
                            left: 8%;
                            right: 0;
                            font-size: 4.2em;
                        }

                        .text {
                            left: 8%;
                            top: 47%;
                            right: 0;
                            font-size: 4em;
                            line-height: 1em;

                            &.right {
                                left: 50%;
                            }

                            &.left {
                                left: 1%;
                                width: 49%;
                            }
                        }

                        .side-image {
                            left: 1.5%;
                            top: 47%;
                            width: 47%;
                            bottom: 38%;

                            &.right {
                                left: 51%
                            }
                        }

                        .description-image {
                            left: 1%;
                            top: 47%;
                            right: 1%;
                            bottom: 22%;
                        }
                    }
                }
            }
        }

        .rotate-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 1em 1em;
        }

    }


    .form-raw {
        margin-top: 1em;
        font-family: monospace;
        font-weight: 600;
        white-space: pre-wrap;
        word-break: break-all;
    }
}
</style>